

















































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
import { api_user } from "@/api";
import { numFormat } from "@/util/string"
import Info from "./info.vue"
@Component({
  components:{
    Info
  }
})
export default class extends More(api_user.get_score) {
  get is_mobile() {
    return OtherModule.is_mobile;
  }
  numFormat = numFormat
}
