







































import { api_user } from "@/api";
import { Vue, Watch,Component } from "vue-property-decorator";
import { numFormat } from "@/util/string";
import { OtherModule } from "@/store/other";

@Component
export default class extends Vue {
  score_info: any = ""

  numFormat = numFormat

  get is_mobile():boolean {
    return OtherModule.is_mobile;
  }

  async get_score_info() {
    this.score_info = await api_user.get_score_info();
  }

    created(){
        this.get_score_info()
    }
}
